.home-mailing-button
    display: flex
    justify-content: center
    align-content: center
    width: 30%
    @media (min-width:1348px)
        width: 25%
    @media (max-width:  1150px)
        width: 40%
    @media (max-width:  640px)
        width: 50%
    @media (max-width:  580px)
        width: 60%
    @media (max-width:  526px)
        button
            font-size: .85rem
            padding: .4rem 1.6rem
    @media (max-width:  400px)
        button
            font-size: .75rem
            padding: .3rem 1.125rem


@media (max-width:  526px)
    #donate-link-button, .db
        font-size: .85rem
        padding: .4rem 1.6rem
    p.head
        font-size: .9rem
    p.vesulo
        font-size: .7rem

@media (max-width:  400px)
    #donate-link-button, .db
        font-size: .75rem
        padding: .3rem 1.125rem
    p.head
        font-size: .85rem
    p.vesulo
        font-size: .66rem

#navbar-link
    display: block
    @media (max-width:  450px)
        display: none

#navbar-link-small, #navbar-placeholder
    display: none
    @media (max-width:  450px)
        display: block

.navbar-expand-cmd
    @media (max-width:  450px)
        margin-right: .3rem !important
        margin-left: .3rem !important 

@media (max-width: 1225px)
    #bg-image
        width: 0%
        height: 0%
    #main-section
        width: 100%
    .full-bg
        background: url("../images/background.jpg"), rgba(255, 255, 255, 0.85)
        background-repeat: no-repeat
        background-size: cover
        background-blend-mode: lighten
        background-position: center

    
h1.head
    @media ( max-width:  650px)
        font-size: 4rem
    @media ( max-width:  535px)
        font-size: 3rem
    @media ( max-width:  360px)
        font-size: 2rem
        

@media ( max-width:  535px)
    #home-section
        margin-left: 1rem !important
        margin-right: 1rem !important
    p.vesulo
        margin-left: 1rem !important
        margin-right: 1rem !important

#navbar-link-small
    transform: translateX(1.75rem)




@media (max-width: 849px)

    .navbar-expand-cmd
        margin-left: 0rem !important
        margin-right: 0rem !important

    .nav-pills .nav-link 
        border-radius: 0rem

    .nav-link, #navbar-link
        padding-left: 1rem !important

@media (max-width: 750px)
    h1.subhead
        font-size: 1.75rem
    p.head
        font-size: .75rem
    p.vesulo
        font-size: .59rem

@media (max-width: 450px)
    h1.subhead
        font-size: 1.5rem
        margin-bottom: 2rem !important
    
@media ( max-width:  1371px)
    .email-button
        font-size: 1rem
    
@media ( max-width:  1325px)
    .email-button
        font-size: .9rem

@media ( max-width:  1228px)
    .email-button
        font-size: .8rem
        width: auto !important
    .cl
        font-size: 1.5rem

@media ( max-width:  1228px)
    .email-button
        margin-bottom: 1rem
        padding: .5rem 1rem
    .cl
        font-weight: 600
        font-size: 1.5rem

@media ( max-width:  1155px)
    #contact-section
        margin-left: 1rem !important
        margin-right: 1rem !important

@media (max-width: 600px)
    .column-grid
        flex: 100%
        max-width: 100%

@media ( max-width:  525px)
    #contact-section
        margin-left: .5rem !important
        margin-right: .5rem !important
    .email-button
        font-size: .75rem
        padding: .3rem .5rem

@media (max-width: 475px)
    #events-section, #gallery-section
        margin-left: 0rem !important
        margin-right: 0rem !important
    .splide__arrow--prev
        left: 0
    .splide__arrow--next
        right: 0
    .gallery-section
        div
            padding-right: 0
    #splide__gallery
        div
            .splide__arrow
                svg
                    width: 2rem
                    height: 2rem


@media (max-width: 415px)
    #splide__events-track, #splide__gallery-track
        margin-left: 1rem !important
        margin-right: .7rem !important
        margin: 0 auto !important
    .splide__arrow--prev
        transform: translateX(-.6rem)
    .splide__arrow--next
        transform: translateX(.6rem)
    #splide__gallery
        div
            .splide__arrow
                svg
                    width: 1.5rem
                    height: 1.5rem