.main
    height: 100vh

.chatt
    object-fit: cover
    width: 100%
    height: 100%

.navbar-brand
    color: $primary !important
    font-family: $heading-font
    font-weight: 700
    .dark
        color: $secondary

.nav-link
    font-family: $heading-font
    font-weight: 400
    font-size: .875rem
    color: $secondary
    &:hover
        color: #000000be

.pt-nav-items
    padding-top: ($spacer * .25 * .7)

.btn-primary
    color: $tertiary
    &:focus
        color: $tertiary
    &:hover
        color: $tertiary

.btn-outline-primary:hover
    color: $tertiary

h1.head
    color: $primary
    font-family: $heading-font
    font-size: 5rem
    font-weight: 700
    span
        color: $secondary

h1.subhead
    color: $primary
    font-family: $heading-font
    font-size: 2.25rem
    font-weight: 700

p.head
    color: $secondary
    font-family: $body-font
    font-size: 1.125rem
    font-weight: 400

button.head
    font-family: $heading-font
    font-size: 1rem
    font-weight: 500

.signup-title
    font-family: $heading-font
    font-size: 1.25rem
    color: $primary
    font-weight: 400

.modal-content
    div.modal-header
        border-bottom: none
        border: none

.signup-body
    color: $secondary
    font-family: $body-font
    font-size: 1rem
    font-weight: 200

.email-input
    border-bottom-left-radius: .75rem
    border-top-left-radius: .75rem
    border-width: .125rem
    border-color: $primary
    font-size: .875rem
    font-family: $body-font

.email-submit
    border-top-right-radius: .75rem
    border-bottom-right-radius: .75rem
    font-size: .875rem
    font-family: $heading-font

.db
    margin: 0 auto
    display: block

.cl
    font-family: $heading-font
    font-weight: 300
    font-size: 2rem
    color: $primary

.email-button
    font-family: $body-font
    font-size: 1.1rem
    font-weight: 400
    color: $tertiary

.custom-input
    border-radius: .75rem
    border-width: .0625rem
    border-color: $primary
    font-size: .875rem
    font-family: $body-font
    resize: none

.lb
    padding-bottom: .5rem
    padding-top: 1rem
    font-size: .875rem
    font-family: $body-font
    font-weight: 400
    color: $secondary

.submit-button
    padding: .5rem .75rem

.card-title
    font-family: $heading-font
    font-size: 1.25rem
    font-weight: 600
    line-height: 1.875rem

.card-subtitle
    font-family: $heading-font
    font-size: 1rem
    font-weight: 200
    line-height: 1.875rem
    margin-bottom: 2rem
    margin-top: 1rem

.card-text
    font-family: $body-font
    font-size: .9rem
    font-weight: 200
    line-height: 1.875rem
    margin-bottom: 2rem
    height: 9.375rem
    display: -webkit-box
    -webkit-line-clamp: 5
    -webkit-box-orient: vertical
    overflow: hidden

.card-image
    width: 100%
    height: 20rem
    object-fit: cover
    border-top-right-radius: .375rem
    border-top-left-radius: .375rem

.open-photos
    border-top-right-radius: 0
    border-top-left-radius: 0

.het
    line-height: 2

.gallery-cover
    object-fit: contain

.row-grid
    display: flex
    flex-wrap: wrap
    padding: 0 4px
    box-sizing: border-box

/* Create two equal columns that sits next to each other */
.column-grid
    flex: 50%
    padding: 0 4px
    box-sizing: border-box

.column-grid img
    margin-top: 8px
    vertical-align: middle
    box-sizing: border-box

.splide__arrow:disabled
    display: none

#main-section
    overflow: auto

.navbar-light .navbar-toggler-icon
    background-image: url("../images/menu_24px.svg")

.navbar-light .navbar-toggler
    border: none
    color: transparent

.navbar-toggler-icon
    width: 1.25rem
    height: 1.25rem

.btn
    margin-bottom: 1rem

.email-submit
    margin-bottom: 0rem

.input-group
    margin-bottom: 1rem

p.vesulo
    font-size: .88rem
    color: #898989
    font-family: $body-font
    font-weight: 300
    position: absolute
    bottom: 0
#vesulo
    height: calc(25% - 1.5rem - 72px)
    position: relative