$primary: #FF3131
$secondary: black
$tertiary: white

$navbar-light-active-color: $tertiary
$navbar-light-color: $secondary
$navbar-light-hover-color: rgba($secondary, .75)
$navbar-light-brand-color: $primary
$navbar-nav-link-padding-x: .8rem
$navbar-brand-font-size: 1.25rem

$btn-border-radius: .375rem
$btn-padding-x: 2.25rem
$btn-padding-y: .75rem

$modal-content-border-radius: .625rem
$btn-close-bg: url(../images/close.svg)
$btn-close-opacity:   1
$btn-close-width: .68rem

$card-border-width: 0

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px,cmd: 850px, lg: 992px, xl: 1200px, xxl: 1400px )